<template>
  <div class="main_page">
    <div class="data_box">
      <div style="display: flex; justify-content: space-between">
        <div class="box_msg" style="width: 20%; background: #ff7878">
          <!-- 今日交易额 -->
          <div class="box_msg_title">{{ language.Todaystransactionamount }}</div>
          <div class="box_msg_info">{{ Todaysamount }} &nbsp;{{ Currency }}</div>
          <!-- 今日提现额 -->
          <div class="box_msg_title">{{ language.Withdrawalamounttoday }}</div>
          <div class="box_msg_info">{{ dayWith }} &nbsp;{{ Currency }}</div>
        </div>
        <div class="box_msg" style="width: 28%; background: #f6a367">
          <div class="box_msg_title">{{ language.Businessdata }}</div>
          <div class="box_msg_info">
            <!-- 昨日交易额 -->
            <div>
              {{ language.Yesterdaystransactionamount }}：<br />{{ yesterdayamount
              }}&nbsp;{{ Currency }}
            </div>
            <!-- 本周交易额 -->
            <div style="margin-left: 20px">
              {{ language.Transactionamountthisweek }}：<br />{{ money }}&nbsp;{{ Currency }}
            </div>
          </div>
          <div class="box_msg_info" style="margin-top: 20px">
            <!-- 月金额 -->
            <div>
              {{ language.Transactionamountforthismonth }}：<br />{{ monthamount
              }}&nbsp;{{ Currency }}
            </div>
            <!-- 累计金额 -->
            <div style="margin-left: 20px">
              {{ language.Accumulatedtransactionamount }}：<br />{{ rechargeTotal
              }}&nbsp;{{ Currency }}
            </div>
          </div>
        </div>
        <div class="box_msg" style="width: 20%; background: #43b100">
          <div class="box_msg_title">{{ language.Amountinformation }}</div>
          <div class="box_msg_info">
            <div style="
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;
              ">
              <div style="display: flex">
                <el-dropdown @command="changeCurrency">
                  <span class="el-dropdown-link" style="display: inline-block; color: #fff">
                    &nbsp;{{ Currency }}
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in options2" :key="item.value" :command="item.value">{{ item.value
                      }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

              <el-dropdown style="cursor: pointer" @command="changeCurrency">
                <span class="el-dropdown-link" style="display: inline-block; color: #fff">
                  {{ language.SwitchCurrency }}
                  <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-for="item in options2" :key="item.value" :command="item.value">{{ item.value
                    }}</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
          <!-- 余额 -->
          <div class="box_msg_title">{{ language.Account_balance }}</div>
          <div class="box_msg_info" style="justify-content: space-between">
            <div v-if="Currency == 'MYR'">{{ money2 }}&nbsp;{{ Currency }}</div>
            <div v-if="Currency == 'THB'">{{ thb_money }}&nbsp;{{ Currency }}</div>
            <div v-if="Currency == 'USDT'">{{ usdt_money }}&nbsp;{{ Currency }}</div>
            <div v-if="Currency == 'INR'">{{ inr_money }}&nbsp;{{ Currency }}</div>
            <div v-if="Currency == 'WanBi'">{{ wanbi_money }}&nbsp;{{ Currency }}</div>
            <div v-if="Currency == 'GoBi'">{{ gobi_money }}&nbsp;{{ Currency }}</div>
            <div v-if="Currency == 'CNY'">{{ rmb_money }}&nbsp;{{ Currency }}</div>
            <div @click="Account_balance()" style="cursor: pointer; padding-left: 20px; font-size: 14px">
              {{ language.balanceinquiry }}
            </div>
            <!-- <div
              v-if="pid == 0"
              style="cursor: pointer; font-size: 14px"
              @click="Balancewithdrawal()"
            >
              {{ language.Balancewithdrawal }}
            </div> -->
          </div>
          <div class="box_msg_title">{{ language.Pendingsettlementamount }}</div>
          <div class="box_msg_info" style="justify-content: space-between">
            {{ noSettledMoney }}&nbsp;{{ Currency }}
          </div>
        </div>
        <!-- 商户信息 -->
        <div class="box_msg" style="width: 20%; background: #68bffd">
          <div class="box_msg_title">{{ language.MerchantInformation }}</div>
          <div class="box_msg_info">{{ language.Merchant_name }}：{{ gameName }}</div>
          <div class="box_msg_info" style="margin-top: 20px">
            {{ language.MerchantID }}：{{ id }}
          </div>
        </div>
      </div>
      <div style="display: flex; flex: 1; height: 100%; margin-top: 20px">
        <div style="
            border-right: 1px solid #efefef;
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 20px;
            box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
            background: #fff;
            border-radius: 10px;
          ">
          <div class="Jump">
            <!-- 交易明细查询按钮 -->
            <!-- <div style="display: flex; align-items: center" @click="Jump(0)">
              {{ language.transaction_detail_enquiry }}
            </div> -->
            <!-- 交易日账单 -->
            <!-- <div style="display: flex; align-items: center" @click="Jump(1)">
              {{ language.Transaction_Day_Statement }}
            </div> -->
            <!-- 切换币种 -->
            <!-- <div style="display: flex; justify-content: center; align-items: center">
              <el-dropdown @command="changeCurrency">
                <span class="el-dropdown-link" style="display: inline-block">
                  &nbsp;{{ Currency }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in options2"
                    :key="item.value"
                    :command="item.value"
                    >{{ item.value }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div> -->
          </div>
          <div style="display: flex; flex-direction: column; align-items: flex-end">
            <!-- 近一周交易金额 -->
            <!-- <div style="display: flex">
              <div>{{ language.Transaction_amount_in_the_last_week }}</div>
              <div style="width: 68px; text-align: right">{{ money }}</div>
            </div> -->
            <!-- 近一周交易笔数 -->
            <!-- <div style="display: flex">
              <div>{{ language.Number_of_transactions_in_the_last_week }}</div>
              <div style="width: 68px; text-align: right">{{ num }}</div>
            </div> -->
          </div>
          <!-- 折线图 -->
          <div id="main" style="flex: 1" v-loading="mainloading"></div>
        </div>
      </div>
    </div>
    <!-- 余额展示 -->
    <el-dialog :title="language.Account_balance" :visible.sync="Account_balanceshow" width="500px">
      <div class="money_big_box">
        <!-- <div>
          <div>
            <span class="money_box">CNY{{ language.balance }}:</span>
            <span>{{ rmb_money }}</span>
          </div>
        </div>
        <div>
          <div>
            <span class="money_box">MRY{{ language.balance }}:</span>
            <span>{{ money2 }}</span>
          </div>
        </div>
        <div>
          <div>
            <span class="money_box">THB{{ language.balance }}:</span>
            <span>{{ thb_money }}</span>
          </div>
        </div>
        <div>
          <div>
            <span class="money_box">USDT{{ language.balance }}:</span>
            <span>{{ usdt_money }}</span>
          </div>
        </div> -->
        <div>
          <div>
            <span class="money_box">INR{{ language.balance }}:</span>
            <span>{{ inr_money }}</span>
          </div>
        </div>
        <!-- <div>
          <div>
            <span class="money_box">WanBi{{ language.balance }}:</span>
            <span>{{ wanbi_money }}</span>
          </div>
        </div>
        <div>
          <div>
            <span class="money_box">GoBi{{ language.balance }}:</span>
            <span>{{ gobi_money }}</span>
          </div>
        </div> -->
      </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取消</el-button> -->
        <el-button type="primary" @click="Account_balanceshow = false">{{
          language.determine
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="language.Withdrawal" :visible.sync="Withdrawaldiv" width="30%">
      <div>
        {{ notictitle }}
      </div>
      <div v-html="noticcontent"></div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">{{
          language.determine
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 公告详情 -->
    <el-dialog :title="language.Notice" :visible.sync="dialogVisible" width="30%">
      <div>
        {{ notictitle }}
      </div>
      <div v-html="noticcontent"></div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">{{
          language.determine
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="language.Withdrawal" :visible.sync="Withdrawaldiv" width="30%">
      <div>
        {{ notictitle }}
      </div>
      <div v-html="noticcontent"></div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="dialogVisible = false">取消</el-button> -->
        <el-button type="primary" @click="dialogVisible = false">{{
          language.determine
        }}</el-button>
      </span>
    </el-dialog>
    <!-- <Withdrawal :Withdrawaltrue="Withdrawaltrue" @Withdrawaltrue="Withdrawaltruebtn" />
    <Addbank :addtrue="addtrue" @addtrue="addtruebtn" /> -->
  </div>
</template>

<script>
// import Withdrawal from "../../components/Withdrawal/Withdrawal.vue";
// import Addbank from "../../components/AddBank/AddBank.vue";
import payment from "../../common/json/payment.json";
import { user, indexdashboard, info, bankcard, wallet } from "../../api/index.js";
export default {
  data() {
    return {
      mainloading: true, //折线图是否显示
      nameloading: true, //账户信息是否显示
      listloading: true, //公告是否显示
      language: "", //语言
      pid: 0,
      second_box1_change: 0, //判断是日账单还是跳转充值明细
      money: this.$store.state.money, //马币
      thb_money: "", //泰币
      usdt_money: "", //usdt
      num: 300000, //近一周交易笔数
      gameName: "", //商户名
      id: "",
      mail: "", //账户名
      money2: "",
      newlist: [], //公告列表
      busy: false,
      page: 1, //第多少页
      limit: 4, //每页好多条
      pages: 1, //共多少页
      dialogVisible: false, //展示公告
      noticcontent: "", //公告名
      notictitle: "", //公告类容
      timeStr: "week", //统计类型
      Withdrawaldiv: false, //是否展示提现按钮
      options2: [
        //币种
      ],
      Currency: "MYR", //币种筛选
      inr_money: "", //印度币
      wanbi_money: "", //wanbi
      gobi_money: "", //gobi
      rmb_money: "", //人民币
      Todaysamount: "",
      yesterdayamount: "",
      monthamount: "",
      dayWith: "", //今日提现
      rechargeTotal: "", //充值总额
      Account_balanceshow: false,
      addtrue: {
        addtrue: false,
        currency: "",
      },
      noSettledMoney: "",
      Withdrawaltrue: {
        Withdrawaltrue: false,
        currency: "",
      },
    };
  },
  components: {
    // Withdrawal,
    // Addbank,
  },
  created() {
    this.options2 = payment.otherCurrencylist;
    this.changelanguage();
    localStorage.setItem("showurl", "/");
  },
  watch: {
    // 语言变化切换语言
    "$store.state.language"() {
      this.changelanguage();
    },
    // 跳转日账单还是明细
    second_box1_change() {
      if (this.second_box1_change == 0) {
        // this.timeStr = "today"
        this.$router.push("/transactionCenter");
      } else if (this.second_box1_change == 1) {
        // this.timeStr = "week"
        this.$router.push("/dailystatement");
      }
      this.getdashboard();
      this.getindexdashboard();
    },
    Currency() {
      this.getdashboard();
      this.getindexdashboard();
      this.getcurrencyinfo();
    },
  },
  mounted() {
    this.init();
    // 在通过mounted调用即可
  },
  methods: {
    // 余额展示
    Account_balance() {
      this.Account_balanceshow = true;
    },
    // 跳转
    Jump(e) {
      if (e) {
        this.$router.push("/dailystatement");
      } else {
        this.$router.push("/transactionCenter");
      }
    },
    // 获取信息
    init() {
      user().then((res) => {
        if (res.code == 1) {
          this.mainloading = true;
          this.pid = res.data.userinfo.pid;
          if (res.data.userinfo.currency != "all") {
            this.Currency = res.data.userinfo.currency;
            for (let i = this.options2.length - 1; i >= 0; i--) {
              if (this.options2[i].value != this.Currency) {
                this.options2.splice(i, 1);
              }
            }
          }
          this.mail = res.data.userinfo.username;
          this.money2 = res.data.userinfo.money;
          this.thb_money = res.data.userinfo.thb_money;
          this.usdt_money = res.data.userinfo.usdt_money;
          this.inr_money = res.data.userinfo.inr_money;
          this.wanbi_money = res.data.userinfo.wanbi_money;
          this.gobi_money = res.data.userinfo.gobi_money;
          this.rmb_money = res.data.userinfo.cny_money;
        }
      });
      this.getdashboard();
      this.getindexdashboard();
      this.getcurrencyinfo();
      // let data = {
      //   page: this.page,
      //   limit: this.limit,
      // };
      // indexnotice({}, data).then((res) => {
      //   if (res.code == 1) {
      //     this.listloading = false;
      //     this.newlist = res.data.list;
      //     this.pages = res.data.count;
      //   }
      // });
      info().then((res) => {
        if (res.code == 1) {
          if (res.code == 1) {
            this.nameloading = false;
            this.gameName = res.data.name;
            this.id = res.data.mch_id;
          }
        }
      });
    },
    addtruebtn() {
      this.addtrue.addtrue = false;
      this.addtrue.currency = this.Currency;
      this.init();
    },
    Withdrawaltruebtn() {
      this.Withdrawaltrue.Withdrawaltrue = false;
      this.Withdrawaltrue.currency = this.Currency;
      this.init();
    },
    // 余额提现
    Balancewithdrawal() {
      let data = {
        currency: this.Currency,
      };
      if (
        this.Currency == "CNY" ||
        this.Currency == "MYR" ||
        this.Currency == "THB" ||
        this.Currency == "INR"
      ) {
        bankcard(data).then((res) => {
          if (res.data.count) {
            this.Withdrawaltrue.Withdrawaltrue = true;
            this.Withdrawaltrue.currency = this.Currency;
            this.Withdrawaltrue.type = "1";
          } else {
            this.addtrue.addtrue = true;
            this.addtrue.currency = this.Currency;
            this.addtrue.type = "1";
          }
        });
      } else {
        wallet(data).then((res) => {
          if (res.data.count) {
            this.Withdrawaltrue.Withdrawaltrue = true;
            this.Withdrawaltrue.currency = this.Currency;
            this.Withdrawaltrue.type = "2";
          } else {
            this.addtrue.addtrue = true;
            this.addtrue.currency = this.Currency;
            this.addtrue.type = "2";
          }
        });
      }
      // this.$router.push("/Merchantwithdrawal");
    },
    getNowFormatDate(e) {
      let date = e,
        seperator1 = "-", //格式分隔符
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

      let currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    },
    getcurrencyinfo() {
      let date = new Date();
      var preDate = new Date(date.getTime() - 24 * 60 * 60 * 1000);
      // 今日
      this.dashboard(
        this.getNowFormatDate(date) +
        " 0:00:00 - " +
        this.getNowFormatDate(date) +
        " 23:59:59",
        1
      );
      // 昨天
      this.dashboard(
        this.getNowFormatDate(preDate) +
        " 0:00:00 - " +
        this.getNowFormatDate(preDate) +
        " 23:59:59",
        2
      );
      // 本月
      this.dashboard(
        this.getNowFormatDate2(preDate) +
        "1 0:00:00 - " +
        this.getNowFormatDate(preDate) +
        " 23:59:59",
        3
      );
    },
    // 获取信息
    dashboard(e, j) {
      let params = {
        timeStr: e,
        currency: this.Currency,
      };
      if (j == 1) {
        params.timeStr = "today";
      } else if (j == 2) {
        params.timeStr = "yesterday";
      } else if (j == 3) {
        params.timeStr = "month";
      }
      indexdashboard("", params).then((res) => {
        this.noSettledMoney = res.data.info.noSettledMoney;
        if (j == 1) {
          this.Todaysamount = res.data.info.totalMoney;
        }
        if (j == 2) {
          this.yesterdayamount = res.data.info.totalMoney;
        }
        if (j == 3) {
          this.monthamount = res.data.info.totalMoney;
        }
        console.log(this.Todaysamount);
      });
    },
    // 切换币种
    changeCurrency(command) {
      this.Currency = command;
    },
    getNowFormatDate2(e) {
      let date = e,
        seperator1 = "-", //格式分隔符
        year = date.getFullYear(), //获取完整的年份(4位)
        month = date.getMonth() + 1, //获取当前月份(0-11,0代表1月)
        strDate = date.getDate(); // 获取当前日(1-31)
      if (month >= 1 && month <= 9) month = "0" + month; // 如果月份是个位数，在前面补0
      if (strDate >= 0 && strDate <= 9) strDate = "0" + strDate; // 如果日是个位数，在前面补0

      let currentdate = year + seperator1 + month + seperator1;
      return currentdate;
    },
    // 获取本周
    getFirstDayOfWeek() {
      let data = {};
      let list1 = [];
      let list2 = [];
      let date = new Date();
      var weekday = date.getDay() || 7; //获取星期几,getDay()返回值是 0（周日） 到 6（周六） 之间的一个整数。0||7为7，即weekday的值为1-7
      var oneDayTime = 24 * 60 * 60 * 1000;
      for (let i = 0; i < weekday; i++) {
        let month = new Date(date - oneDayTime * i).getMonth() + 1;
        let strDate = new Date(date - oneDayTime * i).getDate();
        if (month < 10) {
          month = "0" + month;
        }
        if (strDate < 10) {
          strDate = "0" + strDate;
        }
        list1.unshift(month + "-" + strDate);
        list2.unshift(0);
      }
      data = {
        list1,
        list2,
      };
      return data;
    },
    // 获取基础数据
    getindexdashboard() {
      let indexdata = {
        currency: this.Currency,
      };
      indexdashboard({}, indexdata).then((res) => {
        if (res.code == 1) {
          this.dayWith = res.data.info.dayWith;
          this.rechargeTotal = res.data.info.rechargeTotal;
        }
      });
    },
    // 获取折线图数据
    getdashboard() {
      let indexdata = {
        timeStr: this.timeStr,
        currency: this.Currency,
      };
      if (this.Currency == "USDT") {
        indexdata.timeStr = "day";
      }
      indexdashboard({}, indexdata).then((res) => {
        if (res.code == 1) {
          this.money = res.data.info.totalMoney;
          this.num = res.data.info.totalNumber;
          let data = this.getFirstDayOfWeek();
          for (let i = 0; i < res.data.list.X.length; i++) {
            for (let j = 0; j < data.list1.length; j++) {
              if (data.list1[j] == res.data.list.X[i]) {
                data.list2[j] = res.data.list.Y[i];
              }
            }
          }
          this.echartsInit(data);
        }
      });
    },
    // 提现按钮
    Withdrawal() {
      this.Withdrawaldiv = true;
    },
    // 查看详情
    scrrlist_btn(e) {
      this.dialogVisible = true;
      this.notictitle = e.title;
      this.noticcontent = this.changenotic(e.content);
    },
    echartsInit(e) {
      //柱形图
      //因为初始化echarts 的时候，需要指定的容器 id='main'
      let option = {
        backgroundColor: "#fff",
        tooltip: {
          textStyle: {
            fontSize: 30, // 字体大小
          },
        },
        grid: {
          bottom: 20,
          left: 70,
          right: 70,
          containLabel: true,
          //grid区域是否包含坐标轴的刻度标签
        },
        xAxis: {
          type: "category",
          data: e.list1,
          boundaryGap: false,
          axisLine: { onZero: false },
          axisLabel: {
            textStyle: {
              fontSize: 14,
              fontWeight: 800,
              color: "#000",
            },
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            textStyle: {
              fontSize: 14,
              fontWeight: 800,
              color: "#000",
            },

            formatter: function (value) {
              if (value >= 1000 && value < 10000) {
                value = value / 1000 + "K";
              } else if (value >= 10000) {
                value = value / 10000 + "W";
              }
              return value;
            },
          },
        },
        series: [
          {
            data: e.list2,
            type: "line",
            // smooth: true
          },
        ],
      };
      this.$echarts.init(document.getElementById("main")).setOption(option);
    },
    changelanguage() {
      this.language = this.$units();
    },
    // 转化公告格式
    changenotic(e) {
      e = e.replace(/&amp;/g, "&");
      e = e.replace(/&lt;/g, "<");
      e = e.replace(/&gt;/g, ">");
      e = e.replace(/&nbsp;/g, " ");
      e = e.replace(/&#39;/g, '"');
      e = e.replace(/&quot;/g, '"');
      return e;
    },
    reducepage() {
      if (this.limit == 1) {
        return;
      }
      this.limit = this.limit - 1;
      // let data = {
      //   page: this.page,
      //   limit: this.limit,
      // };
      // indexnotice({}, data).then((res) => {
      //   if (res.code == 1) {
      //     this.newlist = res.data.list;
      //   }
      // });
    },
    addpage() {
      this.limit = this.limit + 1;
      // let data = {
      //   page: this.page,
      //   limit: this.limit,
      // };
      // indexnotice({}, data).then((res) => {
      //   if (res.code == 1) {
      //     this.newlist = res.data.list;
      //   }
      // });
    },
  },
};
</script>
<style scoped>
.main_page {
  /* width: calc(100vw - 40px); */
  height: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  /* overflow-x: scroll; */
}

.data_box {
  width: 100%;
  flex: 1;
  height: calc(100vh - 120px);
  /* overflow: hidden;
    overflow-x: scroll; */
  /* width: calc(100vw - 40px); */
  /* border: 1px solid #000000; */
  /* padding: 20px; */
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
}

.right_box {
  /* margin-left: 50px; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 20px;
}

.right_item {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}

.right_item:not(:last-child) {
  border-bottom: 1px dashed rgb(203, 203, 203);
}

.scrrlist {
  flex: 1;
  /* height: calc(100vh - 430px); */
}

.scrrlist_item {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  padding: 20px 0;
}

.money_big_box {
  font-size: 20px;
}

.money_big_box>div {
  border: 1px solid #efefef;
  border-bottom: none;
}

.money_big_box>div:last-child {
  border-bottom: 1px solid #efefef;
}

.money_big_box>div>div {
  display: flex;
}

.money_big_box>div>div>span {
  padding: 10px 0;
  display: inline-block;
  flex: 1;
  text-align: center;
}

.money_big_box>div>div>span:last-child {
  border-left: 1px solid #efefef;
}

.money_box {
  display: inline-block;
}

.scrrlist_item:hover {
  color: rgb(255, 208, 75);
}

.scrrlist_item:not(:first-child) {
  border-top: 1px dashed rgb(203, 203, 203);
}

.Jump {
  display: flex;
  align-content: center;
}

.money_item {
  display: flex;
  border-bottom: 1px solid #efefef;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.money_item:last-child {
  border-bottom: none;
}

.money_item>div:last-child {
  display: flex;
  align-items: center;
}

.money_item>div:last-child>div:first-child {
  margin-right: 10px;
}

.Jump>div {
  padding: 0 20px;
  cursor: pointer;
}

.box_msg {
  box-shadow: 10px 10px 10px rgb(0 0 0 / 35%);
  padding: 10px;
  color: #fff;
}

.box_msg_info {
  margin-left: 20px;
  display: flex;
}

/* .Jump > div:first-child {
  border-right: 2px solid #eeeeee;
} */
</style>
